import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Carousel from 'react-bootstrap/Carousel';
import * as Helpers from '../../helpers';
import Faqs from './Faqs';
import HomePagination from './HomePagination';
import {
  Navbar, Button, Footer, AnimatedSection, Input
} from '../../components';

import bambooTroveRiseChaka from '../../assets/images/Carrot.mp4';
// import coinsVideo from '../../assets/images/coins.mp4';
// import bambooLogo from '../../assets/images/bamboo-logo-grey.png';
// import troveLogo from '../../assets/images/trove-logo-grey.png';
// import dashboardOnPhone from '../../assets/images/carrot-dashboard-phone.png';
// import cashAndBarChart from '../../assets/images/cash-bar-chart.png';
import lighteningIcon from '../../assets/images/lightening-icon.svg';
import serverIcon from '../../assets/images/server-icon.svg';
import lockIcon from '../../assets/images/lock-icon.svg';
import creditLine from '../../assets/images/credit-line.png';
import lowerRateIcon from '../../assets/images/lower-rate-icon.svg';
import higherRateIcon from '../../assets/images/higher-rate-icon.svg';
import flexibleIcon from '../../assets/images/flexible-icon.svg';
import tewntyKBold from '../../assets/images/twenty-k-bold.png';
import done from '../../assets/images/done.svg';
import error from '../../assets/images/error-logo.png';

import './index.css';
import './mobile.css';

class Home extends Component {
  constructor(props) {
    super(props);
    this.ref = React.createRef();
    this.state = {
      getStartedData: {
        email: '',
        emailPermit: false,
        firstname: '',
        lastname: '',
        message: '',
        userType: 'Individual',
      },
      invalidMessage: {
        email: '',
      },
      modalShow: false,
      showError: false,
      showForm: true,
      showSuccess: false,
    };
  }

  componentDidUpdate(prevprops) {
    const { businessEmail } = this.props;
    if (businessEmail !== prevprops.businessEmail) {
      this.handleFormInput('email', businessEmail);
    }
  }

  handleFormInput = (dataField, newData) => {
    const { getStartedData } = this.state;
    this.setState({
      getStartedData: {
        ...getStartedData,
        [dataField]: newData,
      },
    });
  };

  handleChange = e => {
    const dataField = e.target.name;
    const newData = e.target.value;
    this.handleFormInput(dataField, newData);
    if (dataField === 'email') {
      this.setState({
        invalidMessage: {
          email: newData.trim() === '' || (/^[^@]+@[^@]+\.[^@]+$/.test(newData.trim())) ? '' : 'Invalid email address',
        },
      });
    }
  }

  handleFormSubmit = () => {
    const { contact } = this.props;
    const { getStartedData } = this.state;
    if ((/^[^@]+@[^@]+\.[^@]+$/.test(getStartedData.email.trim()))) {
      contact(
        getStartedData.email,
        getStartedData.emailPermit,
        getStartedData.fullname ? Helpers.capitalizeFirstLetter3(getStartedData.fullname) : '-',
        getStartedData.message ? getStartedData.message : '-',
        getStartedData.userType,
        () => {
          this.setState({ showForm: false, showSuccess: true });
          setTimeout(() => {
            this.setState({
              getStartedData: {
                email: '',
                emailPermit: false,
                fullname: '',
                message: '',
                userType: getStartedData.userType,
              },
              invalidMessage: {
                email: '',
              },
              modalShow: false,
              showError: false,
              showForm: true,
              showSuccess: false,
            });
          }, 7000);
        },
        () => {
          this.setState({ showError: true, showForm: false });
          setTimeout(() => {
            this.setState({
              getStartedData: {
                email: '',
                emailPermit: false,
                fullname: '',
                message: '',
                userType: getStartedData.userType,
              },
              invalidMessage: {
                email: '',
              },
              modalShow: false,
              showError: false,
              showForm: true,
              showSuccess: false,
            });
          }, 7000);
        }
      );
    }
  };

  handleModal = modalState => {
    this.setState({ modalShow: modalState });
  }

  render() {
    const {
      modalShow, showSuccess, showForm, getStartedData, invalidMessage, showError,
    } = this.state;

    const { contact } = this.props;

    return (
      <div id="home">
        <Navbar />

        {/* section1 */}
        <AnimatedSection>
          <section id="home__section-1">
            <div className="title">
              <div>
                Credit without the
                {' '}
                <span>hassle.</span>
              </div>
            </div>
            <div className="text">
              <div>
                We help businesses and their customers access a line of credit collaterized by
                their assets (Stocks, Cash & Crypto)
              </div>
            </div>
            <div className="button-box">
              <Link style={{ textDecoration: 'none' }} to="/signup">
                <Button cta>
                  Start using Carrot
                </Button>
              </Link>
            </div>
            <div className="animation-box mobile-display-none">
              <video autoPlay loop muted className="animation">
                <source src={bambooTroveRiseChaka} type="video/mp4" />
                Your browser does not support this feature.
              </video>
            </div>
            {/* <div className="text-2">
              In business with leaders in the investment industry
            </div>
            <div className="partners-array">
              <img src={bambooLogo} alt="Bamboo logo" style={{ height: '19px' }} />
              <img src={troveLogo} alt="Trove logo" />
            </div> */}
          </section>
        </AnimatedSection>

        {/* section-2 */}
        <AnimatedSection>
          <section id="home__section-2">
            <div className="title">
              <div>
                Enabling businesses to extend a
                {' '}
                <span>line of credit</span>
                {' '}
                to their customers.
              </div>
            </div>

            <Carousel className="cards" id="cardsMobile">
              <Carousel.Item>
                <div className="card left-card">
                  <div className="title">
                    For Individuals
                  </div>
                  <div className="subtitle">
                    Access to Liquidity
                  </div>
                  <div className="text">
                    Individuals can get access to credit collateralized by a diverse
                    range of assets, including stocks, cash, and cryptocurrency. This provides access
                    to credit without relying on traditional forms of collateral or having to liquidate assets.
                  </div>
                  {/* <img className="mobile-display-none" src={dashboardOnPhone} alt="carrot dashboard on phone" /> */}
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="card right-card">
                  <div className="title">
                    For Businesses
                  </div>
                  <div className="subtitle">
                    AUM Retention
                  </div>
                  <div className="text">
                    Our solution empowers businesses to offer credit options
                    to their customers, eliminating the need for them to sell off
                    their assets to get access to funds. By providing access to credit
                    as an alternative to liquidation of assets, businesses can preserve
                    their Assets Under Management and maintain a stronger financial portfolio.
                  </div>
                  {/* <img className="mobile-display-none" src={cashAndBarChart} alt="cash and bar chart" /> */}
                </div>
              </Carousel.Item>
            </Carousel>

            <div className="cards" id="cardsWeb">
              <div className="card left-card">
                <div className="title">
                  For Individuals
                </div>
                <div className="subtitle">
                  Access to Liquidity
                </div>
                <div className="text">
                  Individuals can get access to credit collateralized by a
                  diverse range of assets, including stocks, cash, and cryptocurrency.
                  This provides access to credit without relying on traditional forms of collateral
                  or having to liquidate assets.
                </div>
                {/* <img className="mobile-display-none" src={dashboardOnPhone} alt="carrot dashboard on phone" /> */}
              </div>

              <div className="card right-card">
                <div className="title">
                  For Businesses
                </div>
                <div className="subtitle">
                  AUM Retention
                </div>
                <div className="text">
                  Our solution empowers businesses to offer credit options to their customers, eliminating the need for
                  them to sell off their assets to get access to funds. By providing access to credit as an alternative
                  to liquidation of assets, businesses can preserve their Assets Under Management and maintain a stronger
                  financial portfolio.
                </div>
                {/* <img className="mobile-display-none" src={cashAndBarChart} alt="cash and bar chart" /> */}
              </div>
            </div>
          </section>
        </AnimatedSection>

        {/* section-4 */}
        <AnimatedSection>
          <section id="home__section-4">
            <div className="title">
              <div>
                Leverage your investment assets to access a line of credit.
              </div>
            </div>
            <div className="cards">
              <div className="card">
                <div className="image-box">
                  <img src={lighteningIcon} alt="lightening icon" />
                </div>
                <div className="title">
                  <div className="title-bar" />
                  Fast
                </div>
                <div className="text">
                  We understand that time is of the essence when liquidity is needed, which is why we prioritize
                  disbursements, ensuring that approved credit applications are processed promptly.
                </div>
              </div>
              <div className="card">
                <div className="image-box">
                  <img src={lockIcon} alt="lock icon" />
                </div>
                <div className="title">
                  <div className="title-bar" />
                  Secure
                </div>
                <div className="text">
                  Securely collateralize stocks, cash, and crypto for access to credit.
                </div>
              </div>
              <div className="card">
                <div className="image-box">
                  <img src={serverIcon} alt="server icon" />
                </div>
                <div className="title">
                  <div className="title-bar" />
                  Simple
                </div>
                <div className="text">
                  Our streamlined online credit application process simplifies borrowing, eliminating paperwork and delays.
                </div>
              </div>
              <div className="card">
                <div className="image-box">
                  <img src={serverIcon} alt="server icon" />
                </div>
                <div className="title">
                  <div className="title-bar" />
                  Customizable
                </div>
                <div className="text">
                  We have credit options that can be tailored to each individual’s financial needs, short-term or long-term.
                </div>
              </div>
            </div>
          </section>
        </AnimatedSection>

        {/* section-5 */}
        <AnimatedSection>
          <section id="home__section-5">
            <div className="left-content mobile-display-none">
              <img src={creditLine} alt="Carrot as a credit line" />
            </div>
            <div className="right-content">
              <div className="title">
                Over 5k users trust Carrot
              </div>
              <div className="cards">
                <div className="card">
                  <img src={lowerRateIcon} alt="lower rates icon" />
                  <div className="title">
                    Access to Funds Without Liquidating
                  </div>
                  <div className="text">
                    Our solution allows you to access funds quickly and conveniently, using your existing portfolio
                    as collateral. This means you can maintain your investments while still meeting your immediate
                    financial needs.
                  </div>
                </div>
                <div className="card">
                  <img src={flexibleIcon} alt="flexible icon" style={{ width: '18px' }} />
                  <div className="title">
                    Flexible Terms
                  </div>
                  <div className="text">
                    Our line of credit offers more flexibility in terms of credit amounts,
                    repayment schedules, and interest rates, allowing individuals to customize
                    their financing to suit their specific needs and circumstances.

                  </div>
                </div>
                <div className="card">
                  <img src={higherRateIcon} alt="higher approval icon" />
                  <div className="title">
                    Simple, Fast Credit Application Process
                  </div>
                  <div className="text">
                    We have designed our credit application process to be as quick and
                    straightforward as possible. Eligibility status are available on user
                    dashboards and our application process takes less time than making a cup of coffee.
                  </div>
                </div>
              </div>
            </div>
          </section>
        </AnimatedSection>

        {/* section-6 */}
        <AnimatedSection>
          <div id='customer'>What Our Customers Are Saying About Us.</div>
          <section id="home__section-6-pagination">
            <HomePagination />
          </section>
        </AnimatedSection>

        {/* section-7 */}
        <AnimatedSection>
          <section id="home__section-6">
            <div className="large-card">
              <div className="left-content">
                <div className="title">
                  Our users are getting up to
                  {' '}
                  <span>$20k</span>
                  {' '}
                  in credit!
                </div>
                <div className="text">
                  For life’s special moments and its emergencies, we have created a credit product that covers every need.
                  <br />
                  <br />
                  We are also currently discussing with several financial institutions to bring Carrot to their customers.
                </div>
              </div>

              <div className="right-img">
                <img src={tewntyKBold} alt="Carrot dashboard pc screen" />
              </div>
            </div>
          </section>
        </AnimatedSection>

        <AnimatedSection>
          <section id="home__faq-section">
            <div className="title">
              Frequently Asked Questions
            </div>
            <Faqs />
          </section>
        </AnimatedSection>

        <AnimatedSection>
          <section id="home__section-7">
            <div id="issue">
              Having any issues with your account or getting started.
              {' '}
              <span>Talk to us</span>
            </div>
            <div id="button-box" onClick={() => this.handleModal(true)}>
              <Button cta>
                Contact Us
              </Button>
            </div>
          </section>
        </AnimatedSection>

        <AnimatedSection>
          <Footer />
        </AnimatedSection>

        <div id="modalBehavior">
          <div>
            <Modal
              show={modalShow}
              onHide={() => this.handleModal(false)}
              size="lg"
              backdrop="static"
              id="modal"
              centered
            >
              { showForm && (
                <div>
                  <Modal.Header closeButton id="modal-header">
                    Send us a message
                  </Modal.Header>
                  <Modal.Body id="modal-body">
                    <h4>Account Type</h4>

                    <div id="choose">
                      <div className={`account-type-button ${getStartedData.userType === 'Individual' ? 'highlight' : ''}`} id="chooseIndividual" onClick={() => this.handleFormInput('userType', 'Individual')}>Individual</div>
                      <div id="chooseBusiness" className={`account-type-button ${getStartedData.userType === 'Business' ? 'highlight' : ''}`} onClick={() => this.handleFormInput('userType', 'Business')}>Business</div>
                    </div>

                    <Container>
                      <Row>
                        <Form id="formInput">
                          <div id="outerForm">
                            <div>
                              <Input
                                placeholder="First Name"
                                name="firstname"
                                required
                                onChange={this.handleChange}
                                value={getStartedData.firstname}
                                boldLabel
                              />
                            </div>
                            <div>
                              <Input
                                placeholder="Last Name"
                                name="lastname"
                                required
                                onChange={this.handleChange}
                                value={getStartedData.lastname}
                                boldLabel
                              />
                            </div>
                          </div>
                          <div>
                            <Input
                              placeholder="Email"
                              name="email"
                              required
                              onChange={this.handleChange}
                              value={getStartedData.emailname}
                              invalidMessage={invalidMessage.email}
                              boldLabel
                            />
                          </div>
                          <div className="contact-form-input">
                            <Input
                              placeholder="Message"
                              name="message"
                              required
                              onChange={this.handleChange}
                              value={getStartedData.message}
                              boldLabel
                            />
                          </div>
                        </Form>
                      </Row>
                    </Container>

                    <div id="button-box" contact={contact}>
                      <Button onClick={this.handleFormSubmit} disabled={getStartedData.email === ''} cta>
                        Submit
                      </Button>
                    </div>
                  </Modal.Body>
                </div>
              )}

              { showSuccess && (
                <div>
                  { getStartedData.userType !== 'Business' && (
                    <div>
                      <Modal.Header closeButton id="modal-header" />
                      <div className="successful-logo-container">
                        <img src={done} className="successful-logo" alt="done-logo" />
                      </div>

                      <div className="header-message">
                        <div id="heading-modal">
                          We have received your email
                        </div>

                        <div id="subheading-modal">
                          Thank you for reaching out to us!
                        </div>
                      </div>
                    </div>
                  )}

                  { getStartedData.userType === 'Business' && (
                    <div>
                      <Modal.Header closeButton id="modal-header" />
                      <div className="successful-logo-container">
                        <img src={done} className="successful-logo" alt="done-logo" />
                      </div>

                      <div className="header-message">
                        <div id="heading-modal">
                          Thank you for reaching out to us!
                        </div>

                        <div id="subheading-modal">
                          Check your email for the next steps
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}

              { showError && (
                <div>
                  <Modal.Header closeButton id="modal-header" />
                  <div className="successful-logo-container">
                    <img src={error} className="successful-logo" alt="done-logo" />
                  </div>

                  <div className="header-message">
                    <div id="heading-modal">
                      Failed to Send Message
                    </div>

                    <div id="subheading-modal">
                      Please try again
                    </div>
                  </div>
                </div>
              )}
            </Modal>
          </div>
        </div>
      </div>
    );
  }
}

export default Home;
